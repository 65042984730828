/* src/components/ServicesSlider.css */
.slick-slider {
  width: auto;
  height: 500px; /* Set a fixed height for the slider container */
  overflow: hidden; /* Ensure no overflow */
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px; /* Add padding to ensure consistent spacing */
  height: 100%; /* Ensure slides take full height of the container */
}

.slick-dots li button:before {
  color: #000;
}

.slick-prev:before, .slick-next:before {
  color: #000;
}

@media (max-width: 600px) {
  .slick-slide {
    display: flex;
    justify-content: center;
    padding: 16px; /* Add consistent padding on mobile */
    height: auto; /* Allow auto height on mobile */
  }

  .card {
    width: calc(100% - 32px); /* Ensure cards take full width minus margin on mobile */
    margin: 16px; /* Add consistent margin */
  }
}

.service-card-container {
  padding: 5px;
  margin: 5px;
  margin-top: 5px; /* Adjust the margin as needed */
}

.service-help-block {
  padding-top: 5px;
  margin-top: 5px;
}

.slick-slide {
  padding: 5px;
  margin: 5px;
  margin-top: -10px; /* Adjust the margin as needed */
}


/* How We Can Help Section */
.ourservices {
  background-color: #f8f9fa;
  color: #000;
  padding: 5px;
  text-align: center;
  border-bottom: 2px solid var(--highlight-color);
  margin-bottom: 2rem;
  font-family: 'Roboto', sans-serif;
  height: auto;
}

.ourservices h2{
  text-align: center;
  color: #000;
  align-items: center;
}

.ourservices h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.ourservices p {
  font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 15px;
}

.service-help-block {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 2rem;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s;
  min-height: 550px; /* Adjust height as needed */
  overflow: hidden;
  position: relative;
}

.service-help-block:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-help-block svg {
  margin-bottom: 10px;
  color: var(--highlight-color);
}

.service-help-block .content {
  transition: opacity 0.2s;
  opacity: 0;
  justify-self: flex-start;
}

.service-help-block .title {
  transition: opacity 0.2s;
  opacity: 1;
}

.service-help-block:hover .content {
  opacity: 1;
}

.service-help-block:hover .title {
  opacity: 0;
}

/* Button Styles */
.hero-button {
  background-color: var(--highlight-color);
  border: none;
  padding: 10px 20px;
  color: var(--text-color);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin: 10px;
  font-size: 1.1rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.hero-button:hover {
  background-color: var(--accent-color);
  transform: scale(1.05);
}

/* Media Query for Mobile View */
@media (max-width: 600px) {
  .service-help-block {
    margin: 16px; /* Add consistent margin on mobile */
  }

 .ourservices h2 {
    font-size: 1.5rem;
  }

  .ourservices h3 {
    font-size: 1.2rem;
  }

  .ourservices p {
    font-size: 1rem;
  }

  .service-flip-card-front h4 {
    margin: 360px 0; /* Adjust margin for title */
  
  }

  .service-help-block {
    
    height: auto;
    overflow: hidden;
    position: relative;
  }
  
}



.service-flip-card {
  background-color: transparent;
  width: 100%;
  height: 400px; /* Adjust height as needed */
  perspective: 1000px;
  padding: 20px;
  background-size: cover;
  background-position: center;
  border: none; /* Remove the border */
}

.service-flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.service-flip-card:hover .service-flip-card-inner {
  transform: rotateY(180deg);
}

.service-flip-card-front,
.service-flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 20px;
}

.service-flip-card-front {
  display: flex;
  color: rgb(0, 0, 0);
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align items to the start */
  background-size: cover;
  background-position: center;
}

.service-flip-card-front-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end; /* Align content to the bottom */
  padding-bottom: 20px; /* Adjust padding to move content down */
}

.service-flip-card-front h4 {
  margin: 370px 0; /* Adjust margin for title */

}

.service-flip-card-back {
  background-color: rgba(0, 0, 0, 0.8); /* Darken background on flip */
  color: white;
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.service-flip-card-back p {
  font-size: 0.9rem; /* Smaller font size for detail text */
}

.service-help-block {
  margin-bottom: 20px; /* Add margin to the help blocks */
}

.service-block {
  margin-bottom: 20px; /* Add margin to the service blocks */
}
